import React from "react"
import { useTranslation } from "react-i18next"

import Process from "../Process/Process"

import {
  BackgroundDiv,
  Content,
  HeaderContainer,
  Label,
  Link,
  Section,
  StyledSubtitle,
  Text,
} from "./Questions.styles"

import QuestionsSlider from "./QuestionsSlider.jsx"

const Questions = () => {
  const { t } = useTranslation()
  return (
    <Section>
      <BackgroundDiv />
      <Content>
        <HeaderContainer>
          <Label>{t("Questions.title")}</Label>
          <StyledSubtitle>{t("Questions.subtitle")}</StyledSubtitle>
        </HeaderContainer>
        <QuestionsSlider />
        <Process />
        <Text>
          {t("Questions.bottomText1")}
          <Link to={process.env.GATSBY_WHATSAPP}>
            {` ${t("Questions.linkText")} `}
          </Link>
          {t("Questions.bottomText2")}
        </Text>
      </Content>
    </Section>
  )
}

export default Questions
