import styled from "styled-components"

import { NavLink } from "../Shared/"
import { device } from "../../styles/"

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 1.2rem;
  flex-direction: column;

  .navContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 580px) {
    flex-direction: row;

    .navContainer {
      display: flex;
      flex-direction: row;
    }
  }

  @media ${device.tablet} {
    padding: 5.5rem 6.5rem;
  }
`
export const Link = styled(NavLink)`
  font-size: 1.6rem;
  margin-top: 2rem;

  @media (min-width: 580px) {
    margin-top: 0;
    margin-left: 3rem;
  }
`
