import styled from "styled-components"

import { CardContainer } from "../Shared/Card/Card.styles"
import Paragraph from "../Shared/Paragraph/"
import { Subtitle } from "../Shared/"
import { colors, device } from "../../styles/"

export const Content = styled(CardContainer)`
  width: 100%;
  box-sizing: border-box;
  max-width: 112rem;
  background-color: ${colors.detailsBlack};
  margin: 0 auto;
  padding: 3.6rem;
  height: fit-content;
`
export const Header = styled(Subtitle)`
  font-size: 1.7rem;
  color: ${colors.lightGray};
  line-height: 1.59;
  letter-spacing: normal;
  margin-top: 0;
`
export const Description = styled(Paragraph)`
  font-size: 1.7rem;
  line-height: 1.56;
  color: ${colors.darkGray};
`

export const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-top: 3.6rem;

  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`
export const Column = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
`

export const HighlightedHeader = styled(Header)`
  color: white;
  font-weight: bold;
`
export const SVGContainer = styled.div`
  display: flex;
  min-width: 4rem;
  margin-top: 0.5rem;
  justify-content: center;
`
export const Section = styled.section`
  margin: 3.6rem 1.2rem;
`
