import React, { memo } from "react"
import { useTranslation } from "react-i18next"

import { Button, DoubleButton } from "../Shared"
import HeaderList from "./Helpers/HeaderList"

import {
  ButtonContainer,
  CustomSubtitle,
  ListContainer,
  RequirementsContainer,
} from "./Join.styles"
import ListItem from "./Helpers/ListItem"
import { NavLink } from "../Shared/"

const Requirements = ({ isFreelancer, setFreelancer }) => {
  const { t } = useTranslation()

  const getItems = (data, index) => {
    return (
      <ListItem
        key={index}
        label={data.label}
        helperText={data.helperText}
        footer={t("Requirements.helpCenter")}
      />
    )
  }

  return (
    <RequirementsContainer id="requirements">
      <div></div>
      <div>
        <CustomSubtitle>{t("Requirements.title")}</CustomSubtitle>
        <DoubleButton
          leftText={t("Requirements.buttonLeft")}
          rightText={t("Requirements.buttonRight")}
          leftClick={() => setFreelancer(false)}
          rightClick={() => setFreelancer(true)}
        />
        <ListContainer>
          {isFreelancer ? (
            <>
              <HeaderList
                type="person"
                text={t("Requirements.freelancers.listHeader")}
              />
              {t(
                "Requirements.freelancers.personalDocumentation"
              ).map((el, index) => getItems(el, index))}
              <HeaderList
                type="fiscal"
                text={t("Requirements.freelancers.list2Header")}
              />
              {t(
                "Requirements.freelancers.fiscalDocumentation"
              ).map((el, index) => getItems(el, index))}
            </>
          ) : (
            <>
              <HeaderList
                type="building"
                text={t("Requirements.businesses.listHeader")}
              />
              {t(
                "Requirements.businesses.companyDocumentation"
              ).map((el, index) => getItems(el, index))}
              <HeaderList
                type="person"
                text={t("Requirements.businesses.list2Header")}
              />
              {t(
                "Requirements.businesses.personalDocumentation"
              ).map((el, index) => getItems(el, index))}
            </>
          )}
          <ButtonContainer>
            <NavLink to={process.env.GATSBY_ONBOARDING}>
              <Button>
                {t(
                  isFreelancer
                    ? "Requirements.freelancers.bottomButton"
                    : "Requirements.businesses.bottomButton"
                )}
              </Button>
            </NavLink>
          </ButtonContainer>
        </ListContainer>
      </div>
      <div className="column2"></div>
    </RequirementsContainer>
  )
}

export default memo(Requirements)
