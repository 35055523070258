import styled from "styled-components"

import { Button, Subtitle } from "../Shared/"
import { CardContainer } from "../Shared/Card/Card.styles"

export const CustomCard = styled(CardContainer)`
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  min-height: 28rem;
  height: min-content;
  max-width: 111.9rem;
  border-radius: 2rem;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.2rem 1.2rem;
`
export const ButtonContainer = styled.div`
  background-color: #1b232d;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28rem;
`

export const SquaredButton = styled(Button)`
  border-radius: 0.8rem;
  margin: 0 8rem;
  width: 31rem;
`

export const CustomSubtitle = styled(Subtitle)`
  max-width: 36rem;
`

export const LeftContent = styled.div`
  padding: 4rem 4.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CardContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;

  @media (max-width: 860px) {
    flex-direction: column;

    .squaredButton {
      margin: 0 4rem;
    }
  }
`
