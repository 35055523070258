import React from "react"
import { useTranslation } from "react-i18next"

import {
  Column,
  Content,
  Description,
  Header,
  HighlightedHeader,
  Section,
  StepsContainer,
  SVGContainer,
} from "./Process.styles"

import Document from "../../images/svg/document.svg"
import Growth from "../../images/svg/growth.svg"
import Laptop from "../../images/svg/laptop.svg"
import Bolt from "../../images/svg/lightning-bolt.svg"

const Process = () => {
  const { t } = useTranslation()
  return (
    <Section id="process">
      <Content isActive={true}>
        <Header>{t("Process.header")}</Header>
        <Description>{t("Process.description")}</Description>
        <StepsContainer>
          <Column>
            <SVGContainer>
              <Document />
            </SVGContainer>
            <div>
              <Header>{t("Process.step1.header")}</Header>
              <Description>{t("Process.step1.description")}</Description>
            </div>
          </Column>
          <Column>
            <SVGContainer>
              <Laptop />
            </SVGContainer>
            <div>
              <Header>{t("Process.step2.header")}</Header>
              <Description>{t("Process.step2.description")}</Description>
            </div>
          </Column>
          <Column>
            <SVGContainer>
              <Bolt />
            </SVGContainer>
            <div>
              <HighlightedHeader>{t("Process.step3.header")}</HighlightedHeader>
              <Description>{t("Process.step3.description")}</Description>
            </div>
          </Column>
          <Column>
            <SVGContainer>
              <Growth />
            </SVGContainer>
            <div>
              <Header>{t("Process.step4.header")}</Header>
              <Description>{t("Process.step4.description")}</Description>
            </div>
          </Column>
        </StepsContainer>
      </Content>
    </Section>
  )
}

export default Process
