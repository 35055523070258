import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

import {
  ColoredDiv,
  ImageContainer,
  ImageStripContainer,
  Text,
  TextContainer,
} from "./ImageStrip.styles"

const ImagesStrip = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      freelancerMobile: file(relativePath: { eq: "strip-freelancers.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      freelancerDesktop: file(
        relativePath: { eq: "strip-freelancers@2x.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      companyMobile: file(relativePath: { eq: "strip-company.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      companyDesktop: file(relativePath: { eq: "strip-company@2x.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const freelancerImageSources = [
    data.freelancerMobile.childImageSharp.fluid,
    {
      ...data.freelancerDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const companyImageSources = [
    data.companyMobile.childImageSharp.fluid,
    {
      ...data.companyDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <ImageStripContainer>
      <ColoredDiv></ColoredDiv>
      <ImageContainer>
        <Img fluid={freelancerImageSources} />
        <TextContainer>
          <Text className="imageText">{t("ImagesStrip.freelancers")}</Text>
        </TextContainer>
      </ImageContainer>
      <ImageContainer>
        <Img fluid={companyImageSources} />
        <TextContainer>
          <Text className="imageText">{t("ImagesStrip.businesses")}</Text>
        </TextContainer>
      </ImageContainer>
      <ColoredDiv></ColoredDiv>
    </ImageStripContainer>
  )
}

export default ImagesStrip
