import styled from "styled-components"

import { colors, fonts } from "../../../styles"

const getFontSize = ({ fontSize }) => fontSize || "1.8rem"

const getLineHeight = ({ lineHeight }) => lineHeight || 1.56

const getColor = ({ color }) => color || colors.paragraphGray

const Paragraph = styled.p`
  ${fonts.barlowRegular}
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  color: ${getColor};
`
export default Paragraph
