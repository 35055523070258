import React, { memo } from "react"

import { ListItemContainer, ItemText } from "../Join.styles"
import Helper from "./Helper"

import CheckMark from "../../../images/svg/checkmark.svg"

const ListItem = ({ label, helperText, footer }) => {
  return (
    <ListItemContainer>
      <CheckMark />
      <div>
        <ItemText>{label}</ItemText>
        {helperText ? (
          <Helper title={label} text={helperText} footer={footer} />
        ) : null}
      </div>
    </ListItemContainer>
  )
}

export default memo(ListItem)
