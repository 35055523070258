import styled from "styled-components"

import Paragraph from "../Shared/Paragraph/"
import { Subtitle, UppercaseLabel } from "../Shared/"

import { device } from "../../styles/"

export const BenfitsContainer = styled.div`
  display: grid;
  max-width: 144rem;
  margin: 9.6rem auto 4rem;
  grid-template-columns: 0fr 8fr 0fr;
  grid-column-gap: 1.2rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr 8fr 1fr;
    grid-column-gap: 2rem;
  }
`
export const CustomSubtitle = styled(UppercaseLabel)`
  font-size: 1.3rem;
`
export const Header = styled(Subtitle)`
  margin: 1.2rem 2.4rem;
  letter-spacing: 0.2px;
  width: fit-content;
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1.2rem;

  max-width: 1000px;
  margin: 7.4rem 0;
  h5 {
    font-weight: bold;
  }

  @media ${device.mobileL} {
    grid-template-columns: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 1.2rem;
    grid-column-gap: 1.2rem;
  }

  @media ${device.tablet} {
    grid-column-gap: 4rem;
  }
`

export const DescriptionHeader = styled(Subtitle)`
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: normal;

  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`

export const Description = styled(Paragraph)`
  font-size: 1.4rem;

  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`
