import styled from "styled-components"

import { device } from "../../styles/"

import { Paragraph } from "../Shared/"

export const BannerContainer = styled.div`
  display: grid;
  direction: rtl;
  grid-template-columns: 5fr 4fr 1fr;
  grid-column-gap: 2rem;
  max-width: 144rem;
  margin: 0 auto;

  .column2 {
    direction: ltr;
    margin: 0;
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    padding: 0 1.2rem;

    .column2 {
      margin: 0 auto;
    }
  }
`
export const Text = styled(Paragraph)`
  max-width: 41.5rem;
  font-size: 1.4rem;

  direction: ltr;
  @media ${device.tablet} {
    font-size: 1.6rem;
  }

  @media ${device.laptop} {
    font-size: 1.9rem;
  }
`

export const SVGContainer = styled.div`
  max-height: 52.15rem;
  position: absolute;
  top: 0;
  left: 78%;
  height: 100%;

  svg {
    height: inherit;
  }
`

export const Column = styled.div`
  position: relative;
`
