// Fonts
import "fontsource-quicksand"
import "fontsource-barlow"

const fonts = {
  barlowRegular: `
  font-family: Barlow;
  font-weight: 400;
  `,
  barlowMedium: `
    font-family: Barlow;
    font-weight: 500;
  `,
  barlowSemiBold: `
  font-family: Barlow;
  font-weight: 600;
  `,
  quickSandLight: `
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  `,
  quickSandMedium: `
  font-family: Quicksand;
  font-weight: 500;
  `,
  quickSandSemiBold: `
  font-family: Quicksand;
  font-weight: 600;
  `,
  quicksandBold: `
  font-family: Quicksand;
  font-weight: 700;
  `,
}

export default fonts
