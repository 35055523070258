import styled, { css } from "styled-components"

import { colors } from "../../../styles/"

import Button from "../Button/"

const NonSelectedStyles = css`
  background: #f2f5f6;
  color: ${colors.label};
`

const getSelectedStyles = ({ selected }) => {
  return selected ? "" : NonSelectedStyles
}

const BaseButton = styled(Button)`
  ${getSelectedStyles};
  width: 18.4rem;

  @media (max-width: 1024px) {
    width: 18rem;
  }

  @media (max-width: 425px) {
    width: 14rem;
  }
`

export const ButtonLeft = styled(BaseButton)`
  border-radius: 21px 0 0 21px;

  @media (max-width: 360px) {
    border-radius: 21px;
  }
`
export const ButtonRight = styled(BaseButton)`
  border-radius: 0 21px 21px 0;

  @media (max-width: 360px) {
    border-radius: 21px;
    margin-top: 1rem;
  }
`
export const Container = styled.div`
  width: 100%;
  max-width: 41.5rem;
  text-align: center;
  padding: 2.4rem 0;
  border-radius: 8px;
  border: solid 1px #e5ecee;
  display: flex;
  justify-content: center;
`
