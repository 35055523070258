import React from "react"
import PropTypes from "prop-types"

import { Header, LinksList } from "./Footer.styles"

const LinksColumn = ({ children, header, ...totherProps }) => {
  return (
    <LinksList>
      <Header>{header}</Header>
      {children}
    </LinksList>
  )
}

LinksColumn.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
}

export default LinksColumn
