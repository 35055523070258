import styled, { css } from "styled-components"

import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"

import { AnchorLink } from "gatsby-plugin-anchor-links"

const linkStyles = css`
  ${fonts.barlowRegular}
  font-size: 1.6rem;
  line-height: 1.56;
  color: ${colors.detailsBlack};
  text-decoration: none;
`

export const Link = styled(AnchorLink)`
  ${linkStyles}
`

export const AnchorTag = styled.a`
  ${linkStyles}
`
