import React from "react"

import { useTranslation } from "react-i18next"

import { Button, Logo } from "../Shared/"
import { Link, NavBarContainer } from "./NavBar.styles"

const NavBar = () => {
  const { t } = useTranslation()
  const sendAnalyticsEvent = () =>
    typeof window !== "undefined" &&
    window.gtag("event", "conversion", {
      send_to: process.env.GATSBY_GOOGLE_ADS_CONVERSION_EVENT,
    })

  return (
    <NavBarContainer>
      <Logo />
      <div className="navContainer">
        <Link to={process.env.GATSBY_HELP_CENTER}>{t("NavBar.link1")}</Link>
        <Link to={process.env.GATSBY_ONBOARDING}>
          <Button onClick={sendAnalyticsEvent}>{t("NavBar.button")}</Button>
        </Link>
        <Link to={process.env.GATSBY_LOGIN}>{t("NavBar.link2")} &#8594;</Link>
      </div>
    </NavBarContainer>
  )
}

export default NavBar
