import React from "react"
import { useTranslation } from "react-i18next"

import { Button, Logo, NavLink } from "../Shared/"

import {
  SectionNavigationContainer,
  LinksContainer,
} from "./Description.styles"

const SectionsNavigation = () => {
  const { t } = useTranslation()
  return (
    <SectionNavigationContainer>
      <div></div>
      <div className="logoContainer">
        <Logo />
      </div>
      <LinksContainer className="linksContainer">
        <NavLink to="/#description">{t("SectionsNavigation.link1")}</NavLink>
        <NavLink to="/#requirements">{t("SectionsNavigation.link2")}</NavLink>
        <NavLink to="/#process">{t("SectionsNavigation.link3")}</NavLink>
        <NavLink to={process.env.GATSBY_ONBOARDING}>
          <Button>{t("SectionsNavigation.button")}</Button>
        </NavLink>
      </LinksContainer>
      <div></div>
    </SectionNavigationContainer>
  )
}

export default SectionsNavigation
