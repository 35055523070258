import styled from "styled-components"

import CardImage from "../../images/png/card.jpg"
import { device } from "../../styles/device"

export const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 0.95fr 5.5fr 0.5fr 2.5fr 0.5fr;
  grid-column-gap: 1rem;
  max-width: 144rem;
  margin: 0 auto;
  padding: 1rem 0 14rem;

  @media (max-width: 578px) {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem;
  }
`

export const CardName = styled.span`
  display: inline-block;
  color: rgba(27, 35, 45, 0.49);
`

export const Image = styled.div`
  background-image: url(${CardImage});
  width: 100%;
  min-height: 30rem;
  height: auto;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 578px) {
    background-size: 55% auto;
  }

  @media (max-width: 409px) {
    background-size: 80% auto;
  }

  @media ${device.tablet} {
    background-size: 130%;
  }

  @media ${device.laptop} {
    height: 45rem;
  }

  @media ${device.laptopL} {
    background-size: 100%;
  }
`
