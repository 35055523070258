import React, { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { graphql, useStaticQuery } from "gatsby"

import Requirements from "./Requirements"
import Testimonial from "./Testimonial"

import { Container, JoinContainer, BlueFigure, Title } from "./Join.styles"

import OlmoRios from "../../images/png/olmorios.png"
import WorqLab from "../../images/svg/worq.svg"
import { Paragraph } from "../Shared"
import { colors } from "../../styles"

const Join = () => {
  const { t } = useTranslation()
  const [isFreelancer, setFreelancer] = useState(false)
  const [data, setData] = useState(t("Testimonials.businesses"))

  const testimonialImages = useStaticQuery(graphql`
    query {
      testimonialPymeMobile: file(relativePath: { eq: "d-testimonials.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialPymeDesktop: file(
        relativePath: { eq: "d-testimonials@2x.png" }
      ) {
        childImageSharp {
          fluid(sizes: "max-width: 350px") {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialFreelancerMobile: file(
        relativePath: { eq: "d-testimonials-freelancer.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialFreelancerDesktop: file(
        relativePath: { eq: "d-testimonials-freelancer@2x.png" }
      ) {
        childImageSharp {
          fluid(sizes: "max-width: 350px") {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const testimonialPyme = [
    testimonialImages.testimonialPymeMobile.childImageSharp.fluid,
    {
      ...testimonialImages.testimonialPymeDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const testimonialFreelancer = [
    testimonialImages.testimonialFreelancerMobile.childImageSharp.fluid,
    {
      ...testimonialImages.testimonialFreelancerDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  useEffect(() => {
    if (isFreelancer) setData(t("Testimonials.freelancer"))
    if (!isFreelancer) setData(t("Testimonials.businesses"))
  }, [isFreelancer, t])

  return (
    <Container>
      <Title>
        {t("Join.titlePt1")} <br></br> {t("Join.titlePt2")}
      </Title>
      <Paragraph
        fontSize="1.9rem"
        color={colors.paragraphGray}
        className="paragraph"
      >
        {t("Join.description")}
      </Paragraph>
      <JoinContainer>
        <BlueFigure></BlueFigure>
        <Testimonial
          imageFluid={isFreelancer ? testimonialFreelancer : testimonialPyme}
          icon={
            !isFreelancer ? <WorqLab /> : <img src={OlmoRios} alt="olmorios" />
          }
          {...data}
        />
        <Requirements
          isFreelancer={isFreelancer}
          setFreelancer={setFreelancer}
          className="column2"
        />
      </JoinContainer>
    </Container>
  )
}

export default Join
