import styled from "styled-components"

export const LinksContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 1.5rem;
  align-items: center;
  justify-items: center;
  justify-self: center;

  @media (min-width: 475px) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 1.5rem;
  }

  @media (min-width: 815px) {
    grid-column-gap: 3rem;
    justify-self: flex-end;
  }
`

export const SectionNavigationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  margin: 0 auto;
  max-width: 144rem;
  width: 100%;
  align-items: center;
  justify-items: center;
  margin: 4rem 0;

  .logoContainer {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 815px) {
    grid-template-columns: 1fr 2fr 6fr 1fr;
    width: 100%;
    grid-column-gap: 2rem;
    margin: 8.8rem 0;

    .logoContainer {
      margin-bottom: 0;
      justify-self: flex-start;
    }
  }
`
