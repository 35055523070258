import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

import { BannerContainer, Column, SVGContainer, Text } from "./Banner.styles"
import { Button, NavLink, SectionTitle } from "../Shared/"
import Rectangle from "../../images/svg/rectangle.svg"

const Banner = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      mobile: file(relativePath: { eq: "evan-dvorkin.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktop: file(relativePath: { eq: "evan-dvorkin@2x.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const source = [
    data.mobile.childImageSharp.fluid,
    { ...data.desktop.childImageSharp.fluid, media: `(min-width: 768px)` },
  ]
  return (
    <BannerContainer>
      <Column className="column3">
        <Img fluid={source} />
        <SVGContainer>
          <Rectangle />
        </SVGContainer>
      </Column>
      <div className="column2">
        <SectionTitle>{t("Banner.title")}</SectionTitle>
        <Text fontSize="1.9rem">{t("Banner.description")} </Text>
        <NavLink to={process.env.GATSBY_ONBOARDING}>
          <Button>{t("Banner.button")}</Button>
        </NavLink>
      </div>
      <div className="column1"></div>
    </BannerContainer>
  )
}

export default Banner
