import React from "react"
import PropTypes from "prop-types"

import { Link, AnchorTag } from "./NavLink.styles"

const NavLink = ({ children, to, ...otherProps }) => {
  const internal = /^\/(?!\/)/.test(to)
  return (
    <>
      {internal ? (
        <Link to={to} {...otherProps}>
          {children}{" "}
        </Link>
      ) : (
        <AnchorTag
          target="_blank"
          rel="noopener noreferrer"
          {...otherProps}
          href={to}
        >
          {children}
        </AnchorTag>
      )}
    </>
  )
}

NavLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
}

export default NavLink
