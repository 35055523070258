import styled from "styled-components"

import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"

export const CardContainer = styled.div`
  width: 29.02rem;
  height: 15.97rem;
  margin: 0 2rem;
  padding: 32px 51px 13.5px 40px;
  border-radius: 10px;
  box-shadow: ${props =>
    props.isActive
      ? ` 0 12px 25px -5px rgba(125, 133, 142, 0.25),
  0 8px 16px -8px rgba(125, 133, 142, 0.05)`
      : "none"};
  background-color: ${colors.white};
`

const textStyles = `
  ${fonts.barlowRegular}
  font-size: 1.7rem;
`

export const Title = styled.h5`
  ${textStyles}
  line-height: 1.59;
  color: ${colors.detailsBlack};
  margin: 0;
`

export const Description = styled.p`
  ${textStyles}
  line-height: 1.56;
  color: #263343;
  margin-top: 0.6rem;
`
