import React from "react"

import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { MainTitle, Paragraph } from "../Shared"

import { CardName, HeroContainer, Image } from "./Hero.styles"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <HeroContainer>
      <div></div>
      <div>
        <MainTitle>
          {t("Hero.title")} <CardName>{t("Hero.cardName")}</CardName>
        </MainTitle>
        <Paragraph fontSize="2.2rem" lineHeight="34px">
          {t("Hero.description")}
        </Paragraph>
      </div>
      <div></div>
      <Image />
      <div></div>
    </HeroContainer>
  )
}

Hero.propTypes = {
  cardName: PropTypes.string,
}

export default Hero
