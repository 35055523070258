import styled from "styled-components"

import { colors, fonts } from "../../styles/"
import { Paragraph, Subtitle, SectionTitle, NavLink } from "../Shared"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 44.1rem;
  background-image: linear-gradient(to bottom, #e5ebed, #ffffff);
  height: auto;
  padding-top: 2.6rem;
  .paragraph {
    margin: 0 1.2rem 8.1rem;
  }
`

export const JoinContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 5fr;
  width: 100%;
  height: 72rem;
  box-sizing: border-box;

  .spacing {
    margin-top: 0.8rem;
  }

  .subtitle {
    font-size: 1.7rem;
    font-weight: 500;
    color: #7d858e;
  }

  @media (max-width: 768px) {
    grid-template-rows: 1fr 12fr;
    grid-template-columns: 1fr;
    padding: 0 1.2rem;
    height: auto;

    .column2 {
      margin: 0 auto;
    }
  }
`

export const BlueFigure = styled.div`
  background-color: ${colors.detailsBlack};
  margin-right: 1rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`

export const TestimonialContainer = styled.div`
  background-color: ${colors.detailsBlack};
  width: 100%;
  height: 100%;
  border-radius: 0 20px 0 0;
  position: relative;
  @media (max-width: 768px) {
    border-radius: 0;
    height: 48rem;
  }
`

export const ImageContainer = styled.div`
  max-width: 33.4rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;

  @media (max-width: 1024px) {
    max-width: 25rem;
  }

  @media (max-width: 1022px) {
    max-width: 15rem;
  }

  @media (max-width: 768px) {
    max-width: 28rem;
  }

  @media (max-width: 568px) {
    max-width: 18rem;
  }

  @media (max-width: 280px) {
    max-width: 4rem;
  }
`

export const TestimonialDetails = styled.div`
  max-width: 16.1rem;
  position: absolute;
  bottom: 4.8rem;
  width: 100%;
  height: auto;
  text-align: right;

  @media (max-width: 768px) {
    max-width: 30rem;
    text-align: right;
  }

  @media (max-width: 414px) {
    max-width: 8rem;
    margin-left: 1rem;
    text-align: right;
    bottom: 2rem;
  }
`
export const TestimonialName = styled.p`
  opacity: 0.8;
  ${fonts.quickSandMedium};
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: right;
  color: ${colors.white};
`
export const TestimonialDescription = styled.div`
  position: absolute;
  top: 8rem;
  height: auto;
  text-align: center;
  opacity: 0.8;
  ${fonts.barlowMedium}
  font-size: 2.25rem;
  line-height: 1.71;
  color: ${colors.white};
  margin: 0 8.2rem;

  @media (max-width: 1160px) {
    font-size: 2rem;
    margin: 0 1rem;
  }

  @media (min-width: 781px) and (max-width: 1022px) {
    font-size: 1.5rem;
  }

  @media (max-width: 769px) {
    top: 0;
    font-size: 2.25rem;
    margin: 0 4.2rem;
  }

  @media (max-width: 411px) {
    font-size: 2rem;
    margin: 0 1rem;
  }

  @media (max-width: 280px) {
    font-size: 1.8rem;
  }
`

export const RequirementsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr 1fr;

  @media (max-width: 768px) {
    padding-top: 3.2rem;
  }
`
export const CustomSubtitle = styled(Subtitle)`
  letter-spacing: 0.009rem;
  margin: 0 0 2.4rem;
`
export const ListContainer = styled.div`
  margin-top: 3.4rem;
`

export const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 8fr;
  align-items: center;
  margin: ${props => (props.isHeader ? "4rem 0 2.4rem 0" : "0")};
`
export const ItemText = styled(Paragraph)`
  font-size: 1.7rem;
  color: ${colors.paragraphGray};
  margin: 0.8rem 0.6rem;
  display: inline-block;
`

export const HelperContainer = styled.div`
  position: relative;
  display: inline-block;

  .card {
    display: none;
    z-index: 999;
    position: absolute;
    right: -8rem;
    top: 1.5rem;
    height: auto;
    padding: 2.4rem;
  }

  .helperFooter {
    font-size: 1.4rem;
  }

  :hover {
    cursor: pointer;
    .card {
      display: inline-block;
    }
  }
`
export const HelperTitle = styled.div`
  ${fonts.barlowRegular};
  font-size: 1.6rem;
  line-height: 1.56;
  color: ${colors.deltai};
`
export const HelperDescription = styled.div`
  ${fonts.barlowRegular};
  font-size: 1.5rem;
  line-height: 1.53;
  margin: 0.5rem 0 1.5rem;
`
export const ButtonContainer = styled.div`
  max-width: 41.5rem;
  padding: 2.5rem;
  background-color: #f2f5f6;
  border-radius: 8px;
  margin-top: 2rem;
  button {
    width: 100%;
  }
`
export const Title = styled(SectionTitle)`
  text-align: center;
`
export const Link = styled(NavLink)`
  color: ${colors.label};
  font-weight: bold;
  font-size: 1.4rem;
`
