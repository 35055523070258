import React from "react"

import Img from "gatsby-image"

import {
  ImageContainer,
  TestimonialContainer,
  TestimonialDescription,
  TestimonialDetails,
  TestimonialName,
} from "./Join.styles"
import { Paragraph } from "../Shared/"

const Testimonial = ({ imageFluid, icon, name, page, testimonial }) => {
  return (
    <TestimonialContainer>
      <TestimonialDescription>{testimonial}</TestimonialDescription>
      <TestimonialDetails>
        <TestimonialName>{name} </TestimonialName>
        {icon}
        <Paragraph color="#98a0a9" fontSize="1.6rem" className="spacing">
          {page}
        </Paragraph>
      </TestimonialDetails>
      <ImageContainer>
        <Img fluid={imageFluid} />
      </ImageContainer>
    </TestimonialContainer>
  )
}

export default Testimonial
