import React from "react"

import Banner from "./Banner"
import Benefits from "./Benefits"
import SectionsNavigation from "./SectionsNavigation"

const Description = () => {
  return (
    <div>
      <SectionsNavigation />
      <Banner />
      <Benefits />
    </div>
  )
}

export default Description
