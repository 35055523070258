import React from "react"
import Helmet from "react-helmet"

export default ({ children }) => (
  <>
    <Helmet>
      <title>delt.ai</title>
      <style type="text/css">{`html {font-size: 62.5%}`}</style>
      <meta name="title" content="delt.ai" />
      <meta
        name="description"
        content="Es la primera tarjeta de servicios acompañada de inteligencia financiera, diseñada para los empresarios y freelancers de México."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://delt.ai/" />
      <meta property="og:title" content="delt.ai" />
      <meta
        property="og:description"
        content="Es la primera tarjeta de servicios acompañada de inteligencia financiera, diseñada para los empresarios y freelancers de México."
      />
      <meta
        property="og:image"
        content="https://deltai-landing-page-files.s3.us-east-2.amazonaws.com/banner_preview_amaranth.png"
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://delt.ai/" />
      <meta property="twitter:title" content="delt.ai" />
      <meta
        property="twitter:description"
        content="Es la primera tarjeta de servicios acompañada de inteligencia financiera, diseñada para los empresarios y freelancers de México."
      />
      <meta
        property="twitter:image"
        content="https://deltai-landing-page-files.s3.us-east-2.amazonaws.com/banner_preview_amaranth.png"
      />

      <script
        src="https://www.googletagmanager.com/gtag/js?id='%REACT_APP_TAG%'"
        async
      ></script>
      <script async>
        {`window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', '${process.env.GATSBY_TAG_MANAGER}');`}
      </script>
      <script>
        {`window['_fs_debug'] = false;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
        window['_fs_org'] = '${process.env.GATSBY_FS_ORG}';
        window['_fs_namespace'] = 'FS';
        (function (m, n, e, t, l, o, g, y) {
          if (e in m) {
            if (m.console && m.console.log) {
              m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
            }
            return;
          }
          g = m[e] = function (a, b, s) {
            g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
          };
          g.q = [];
          o = n.createElement(t);
          o.async = 1;
          o.crossOrigin = 'anonymous';
          o.src = 'https://' + _fs_script;
          y = n.getElementsByTagName(t)[0];
          y.parentNode.insertBefore(o, y);
          g.identify = function (i, v, s) {
            g(l, { uid: i }, s);
            if (v) g(l, v, s);
          };
          g.setUserVars = function (v, s) {
            g(l, v, s);
          };
          g.event = function (i, v, s) {
            g('event', { n: i, p: v }, s);
          };
          g.shutdown = function () {
            g('rec', !1);
          };
          g.restart = function () {
            g('rec', !0);
          };
          g.log = function (a, b) {
            g('log', [a, b]);
          };
          g.consent = function (a) {
            g('consent', !arguments.length || a);
          };
          g.identifyAccount = function (i, v) {
            o = 'account';
            v = v || {};
            v.acctId = i;
            g(o, v);
          };
          g.clearUserCookie = function () {};
        })(window, document, window['_fs_namespace'], 'script', 'user');`}
      </script>
      <script defer>
        {`var APP_ID = '${process.env.GATSBY_INTERCOM_ID}';
        window.intercomSettings = {
          app_id: APP_ID,
        };
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/' + APP_ID;
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
              l();
            } else if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        })();
      `}
      </script>
    </Helmet>
    {children}
  </>
)
