import React from "react"
import styled from "styled-components"

import i18next from "../i18n/config"
import { I18nextProvider } from "react-i18next"

import Description from "../components/Description/Description"
import Footer from "../components/Footer/"
import Hero from "../components/Hero/Hero"
import ImagesStrip from "../components/ImagesStrip/ImagesStrip"
import Join from "../components/Join/Join"
import Layout from "../components/Layout/Layout"
import NavBar from "../components/NavBar/"
import SignUpCard from "../components/SignUpCard/"
import Questions from "../components/Questions/"

import fonts from "../styles/fonts"

const Container = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  ${fonts.quickSandMedium}
`

export default function LandingPage() {
  return (
    <I18nextProvider i18n={i18next}>
      <Layout
        children={
          <Container>
            <NavBar />
            <Hero cardName="*Card Name*" />
            <ImagesStrip />
            <Description />
            <Join />
            <Questions />
            <SignUpCard />
            <Footer />
          </Container>
        }
      />
    </I18nextProvider>
  )
}
