import React from "react"

import { ListItemContainer } from "../Join.styles"
import { LowercaseLabel } from "../../Shared"

import Building from "../../../images/png/building.png"
import Fiscal from "../../../images/png/fiscal.png"
import Person from "../../../images/svg/person.svg"

const HeaderList = ({ type, text }) => {
  const icon = type => {
    const icons = {
      building: <img src={Building} alt="icon" />,
      fiscal: <img src={Fiscal} alt="icon" />,
    }
    return icons[type] || <Person />
  }

  return (
    <ListItemContainer isHeader={true}>
      {icon(type)}
      <LowercaseLabel className="subtitle">{text}</LowercaseLabel>
    </ListItemContainer>
  )
}

export default HeaderList
