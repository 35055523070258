import React from "react"

import { useTranslation } from "react-i18next"

import {
  Brand,
  Container,
  BottomLinks,
  FooterContainer,
  FooterContent,
  Link,
  LinksContainer,
  Icon,
  SmallLink,
} from "./Footer.styles"
import LinksColumn from "./LinksColumn"

const Footer = () => {
  const { t, i18n } = useTranslation()

  const handleLanguageChange = () => {
    if (i18n.language.startsWith("es")) {
      i18n.changeLanguage("en")
      return
    }
    i18n.changeLanguage("es")
  }

  return (
    <FooterContainer>
      <FooterContent>
        <LinksContainer>
          <Link to="/" onAnchorLinkClick={handleLanguageChange}>
            <LinksColumn header={t("Footer.langButton")} />
          </Link>
          <LinksColumn header={t("Footer.column1.header")}>
            <Link>{t("Footer.column1.link1")}</Link>
          </LinksColumn>
          <LinksColumn header={t("Footer.column2.header")}>
            <Link to={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>
              {t("Footer.column2.link1")}
            </Link>
            <Link to={process.env.GATSBY_WHATSAPP}>
              {t("Footer.column2.link2")}
            </Link>
            <Link to={process.env.GATSBY_HELP_CENTER}>
              {t("Footer.column2.link3")}
            </Link>
          </LinksColumn>
          <LinksColumn header={t("Footer.column3.header")}>
            <Link to={process.env.GATSBY_JOBS}>
              {t("Footer.column3.link1")}
            </Link>
          </LinksColumn>
          <LinksColumn header={t("Footer.column4.header")}>
            <Link to={process.env.GATSBY_LINKEDIN}>LinkedIn</Link>
            <Link to={process.env.GATSBY_INSTAGRAM}>Instagram</Link>
            <Link to={process.env.GATSBY_FACEBOOK}>Facebook</Link>
          </LinksColumn>
        </LinksContainer>
        <Container>
          <BottomLinks>
            <SmallLink to={process.env.GATSBY_PRIVACY_NOTICE}>{t("Footer.bottomLinks.link1")}</SmallLink>
            <SmallLink to={process.env.GATSBY_TERMS_AND_CONDITIONS}>
              {t("Footer.bottomLinks.link2")}
            </SmallLink>
            <SmallLink>
              <Icon />
              <Brand>delt.ai</Brand>
              {t("Footer.bottomLinks.link3")}
            </SmallLink>
            <SmallLink>{t("Footer.bottomLinks.link4")}</SmallLink>
          </BottomLinks>
        </Container>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
