import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import detector from "i18next-browser-languagedetector"
import en from "../locales/en/translations"
import es from "../locales/es/translations"

const options = {
  order: ["navigator"],
}

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    lang: "es",
    fallbackLng: "en",
    resources: { es, en },
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
    detection: options,
  })

i18next.languages = ["es", "en"]

export default i18next
