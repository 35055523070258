import styled from "styled-components"

import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"

const Button = styled.button`
  padding: 11px 24px 13px 25px;
  border-radius: 21px;
  border: none;
  background-color: ${colors.deltai};
  color: #fff;
  cursor: pointer;
  outline: none;
  ${fonts.barlowSemiBold}
  font-size: 1.5rem;
  letter-spacing: 0.19px;
  text-align: center;

  &:disabled {
    background: #f2f5f6;
    color: ${colors.label};
    cursor: default;
    box-shadow: none;
  }
`

export default Button
