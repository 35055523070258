import styled from "styled-components"

import { device } from "../../styles/"

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 9fr;
  max-width: 144rem;
  margin: 5rem 0 9.5rem;
`
export const SliderContainer = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    height: 0.8rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }

  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 8px;
  }

  .card {
    min-width: 32rem;
    min-height: 30rem;
    margin: 2rem 1rem;
    padding: 3.2rem 4rem 1.2rem;
    box-sizing: border-box;
  }

  @media ${device.mobileL} {
    .card {
      min-width: 37.8rem;
      min-height: 21.3rem;
    }
  }

  @media (max-width: 768px) {
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
