import React from "react"
import { useTranslation } from "react-i18next"

import { Card } from "../Shared/"
import { GridContainer, SliderContainer } from "./QuestionsSilder.styles"

const QuestionsSilder = () => {
  const { t } = useTranslation()
  return (
    <GridContainer>
      <div />
      <SliderContainer>
        <Card
          className="card"
          isActive={true}
          title={t("QuestionsSlider.card1.question")}
          description={t("QuestionsSlider.card1.answer")}
        />
        <Card
          className="card"
          title={t("QuestionsSlider.card2.question")}
          description={t("QuestionsSlider.card2.answer")}
        />
        <Card
          className="card"
          title={t("QuestionsSlider.card3.question")}
          description={t("QuestionsSlider.card3.answer")}
        />
      </SliderContainer>
    </GridContainer>
  )
}

export default QuestionsSilder
