import styled from "styled-components"
import { colors,  size } from "../../styles/"

import Deltai from "../../images/svg/deltai.svg"

import { LowercaseLabel } from "../Shared/Labels/Labels"
import { NavLink } from "../Shared/"

export const FooterContainer = styled.footer`
  padding: 8rem 1.6rem;
`

export const FooterContent = styled.div`
  max-width: ${size.laptop};
  margin: 0 auto;
`

export const Header = styled(LowercaseLabel)`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
`

export const Link = styled(NavLink)`
  color: ${colors.label};
  margin-bottom: 1rem;
`
export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-items: center;

  @media (max-width: 820px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    justify-items: start;
  }

  @media (max-width: 560px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    justify-items: center;
  }
`
export const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 1rem;
  align-items: flex-start;

  @media (max-width: 560px) {
    align-items: center;
    justify-content: center;
  }
`
export const Icon = styled(Deltai)`
  width: 6.4rem;
  height: 6.4rem;
  path {
    fill: rgba(189, 194, 201, 0.15);
  }
`

export const SmallLink = styled(NavLink)`
  font-size: 1.4rem;
  color: #bdc2c9;
  text-align: center;
`

export const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-end;

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
  }
`

export const BottomLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-column-gap: 1.6rem;
  align-items: end;
  justify-items: center;
  width: 100%;

  a:nth-child(4) {
    justify-self: end;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 2fr 2fr;
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-row-gap: 3rem;

    a:nth-child(4) {
      justify-self: center;
    }
  }
`

export const Brand = styled.p`
  font-size: 1.4rem;
  color: rgba(237, 34, 70, 0.3);
  text-align: center;
  margin-bottom: 0;
`
