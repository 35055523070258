import styled from "styled-components"

import { fonts, colors } from "../../../styles/"
import { device } from "../../../styles/device"

export const MainTitle = styled.h1`
  ${fonts.quickSandMedium}
  line-height: 1.24;
  letter-spacing: normal;
  color: ${colors.detailsBlack};
  font-size: 3rem;

  @media ${device.laptop} {
    font-size: 6.8rem;
  }
`

export const SectionTitle = styled.h2`
  ${fonts.barlowRegular}
  font-size: 2rem;
  line-height: 1.44;
  letter-spacing: 0.3px;
  color: ${colors.detailsBlack};

  @media ${device.mobileL} {
    font-size: 2.4rem;
  }

  @media ${device.laptop} {
    font-size: 3.2rem;
  }
`

export const Subtitle = styled.h3`
  ${fonts.barlowRegular}
  font-size: 2.2rem;
  letter-spacing: 0.2rem;
  line-height: normal;
  color: ${colors.detailsBlack};
`
