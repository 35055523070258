import React from "react"

import { Card, LowercaseLabel } from "../../Shared"

import {
  HelperContainer,
  HelperDescription,
  HelperTitle,
  Link,
} from "../Join.styles"

import Info from "../../../images/svg/info.svg"

const Helper = ({ title, text, footer = {} }) => {
  const { description, linkTo, linkLabel } = footer
  return (
    <HelperContainer>
      <Info />
      <Card
        className="card"
        isActive={true}
        title={<HelperTitle>{title}</HelperTitle>}
        description={<HelperDescription>{text}</HelperDescription>}
      >
        <LowercaseLabel className="subtitle helperFooter">
          {description}{" "}
          <Link to={linkTo || process.env.GATSBY_HELP_CENTER}>{linkLabel}</Link>
        </LowercaseLabel>
      </Card>
    </HelperContainer>
  )
}

export default Helper
