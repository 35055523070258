import React from "react"

import { CardContainer, Description, Title } from "./Card.styles"

const Card = ({ isActive, title, description, className, children }) => {
  return (
    <CardContainer isActive={isActive} className={className}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </CardContainer>
  )
}

export default Card
