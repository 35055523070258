const colors = {
  deltai: "#ed2246",
  deltai2: "#f7477c",
  detailsBlack: "#1b232d",
  label: "#7d858e",
  paragraphGray: "#263343",
  white: "#fff",
  lightGray: "#c3cad2",
  darkGray: "#98a0a9",
}

export default colors
