import styled from "styled-components"
import { size } from "../../styles/"

import { LowercaseLabel } from "../Shared/"

export const ImageStripContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr;
  grid-column-gap: 1rem;
  max-width: 144rem;
  margin: 0 auto;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
`
export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  background-image: linear-gradient(
    to bottom,
    rgba(27, 35, 45, 0),
    #1b232d 97%
  );
`
export const Text = styled(LowercaseLabel)`
  position: absolute;
  top: 60%;
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.75;
  text-shadow: 0 5px 3px rgba(38, 51, 67, 0.55);
  line-height: 1.55;

  @media (max-width: ${size.tablet}) {
    font-size: 1.6rem;
  }
`
export const ColoredDiv = styled.div`
  background-color: rgb(229, 236, 238);
`

export const ImageContainer = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  width: 100%;
`
