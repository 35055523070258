import React from "react"
import { useTranslation } from "react-i18next"

import Send from "../../images/svg/send.svg"
import Dash from "../../images/svg/dash.svg"
import Happy from "../../images/svg/happy-face.svg"

import {
  BenfitsContainer,
  CardsContainer,
  CustomSubtitle,
  Description,
  DescriptionHeader,
  Header,
} from "./Benefits.styles"

const Benefits = () => {
  const { t } = useTranslation()
  return (
    <BenfitsContainer id="description">
      <div></div>
      <div>
        <CustomSubtitle>{t("Benefits.title")}</CustomSubtitle>
        <Header>{t("Benefits.subtitle")}</Header>
        <CardsContainer>
          <div>
            <Send />
            <DescriptionHeader>
              {t("Benefits.column1.header")}
            </DescriptionHeader>
            <Description>{t("Benefits.column1.description")}</Description>
          </div>
          <div>
            <Dash />
            <DescriptionHeader>
              {t("Benefits.column2.header")}
            </DescriptionHeader>
            <Description>{t("Benefits.column2.description")}</Description>
          </div>
          <div>
            <Happy />
            <DescriptionHeader>
              {t("Benefits.column3.header")}
            </DescriptionHeader>
            <Description>{t("Benefits.column3.description")}</Description>
          </div>
        </CardsContainer>
      </div>
      <div></div>
    </BenfitsContainer>
  )
}

export default Benefits
