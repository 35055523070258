import React from "react"
import { useTranslation } from "react-i18next"

import { Paragraph, NavLink } from "../Shared/"

import {
  CardContent,
  CustomCard,
  CustomSubtitle,
  Container,
  ButtonContainer,
  SquaredButton,
  LeftContent,
} from "./SignUpCard.styles"

const SignUpCard = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <CustomCard isActive={true}>
        <CardContent>
          <LeftContent>
            <CustomSubtitle>{t("SignUpCard.title")}</CustomSubtitle>
            <Paragraph fontSize="1.7rem">
              {t("SignUpCard.description")}
            </Paragraph>
          </LeftContent>
          <ButtonContainer>
            {" "}
            <NavLink to={process.env.GATSBY_ONBOARDING}>
              <SquaredButton className="squaredButton">
                {t("SignUpCard.button")} &#8594;
              </SquaredButton>
            </NavLink>
          </ButtonContainer>
        </CardContent>
      </CustomCard>
    </Container>
  )
}

export default SignUpCard
