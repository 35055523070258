import styled from "styled-components"

import { fonts, colors } from "../../../styles/"

export const UppercaseLabel = styled.span`
  ${fonts.barlowRegular}
  font-size: 0.975rem;
  letter-spacing: 0.65px;
  color: ${colors.label};
  text-transform: uppercase;
`

export const LowercaseLabel = styled.span`
  ${fonts.quickSandMedium}
  font-size: 1.27rem;
  font-weight: 500;
  color: ${colors.label};
`
