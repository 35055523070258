import React, { useState } from "react"
import PropTypes from "prop-types"

import { ButtonLeft, ButtonRight, Container } from "./DoubleButton.styles"

const DoubleButton = ({ leftText, rightText, leftClick, rightClick }) => {
  const [selected, setSelected] = useState("left")

  const leftButtonClick = () => {
    setSelected("left")
    if (leftClick) leftClick()
  }

  const rightButtonClick = () => {
    setSelected("right")
    if (rightClick) rightClick()
  }

  return (
    <Container>
      <ButtonLeft selected={selected === "left"} onClick={leftButtonClick}>
        {leftText}
      </ButtonLeft>
      <ButtonRight onClick={rightButtonClick} selected={selected === "right"}>
        {rightText}
      </ButtonRight>
    </Container>
  )
}

DoubleButton.propTypes = {
  leftClick: PropTypes.func,
  leftText: PropTypes.string,
  rightClick: PropTypes.func,
  rightText: PropTypes.string,
}

export default DoubleButton
