import styled from "styled-components"

import { NavLink, Subtitle, Paragraph, UppercaseLabel } from "../Shared/"
import image from "../../images/png/rectangle-background.png"
import { colors, device } from "../../styles/"

export const BackgroundDiv = styled.div`
  height: 100%;
  max-width: 114rem;
  width: 90%;
  position: absolute;
  background: url(${image});

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const Section = styled.section`
  position: relative;
  margin-top: 8rem;
`

export const Content = styled.div`
  position: relative;
  padding: 5rem 0 14rem;
`

export const HeaderContainer = styled.div`
  margin: 0 auto;
  padding: 0 1.2rem;
  max-width: 112rem;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding: 0 3.6rem;
  }
`
export const Label = styled(UppercaseLabel)`
  font-size: 1.3rem;
`
export const StyledSubtitle = styled(Subtitle)`
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-top: 1.2rem;
`
export const Text = styled(Paragraph)`
  font-size: 1.6rem;
  color: ${colors.label};
  width: fit-content;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 1.2rem;
  max-width: 80%;
`

export const Link = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.label};
`
